import { render } from "./AppContainer.vue?vue&type=template&id=d450a8e0&scoped=true"
import script from "./AppContainer.vue?vue&type=script&lang=js"
export * from "./AppContainer.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-d450a8e0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "d450a8e0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d450a8e0', script)) {
    api.reload('d450a8e0', script)
  }
  
  module.hot.accept("./AppContainer.vue?vue&type=template&id=d450a8e0&scoped=true", () => {
    api.rerender('d450a8e0', render)
  })

}

script.__file = "src/components/AppContainer.vue"

export default script